<template>
    <ui-create
        name="groups"
        v-model="form"
        :title="title"
        :id="id"
    >
        <template #body >
            <ui-crud
                v-model="form"
                :rows="rows"
            />
        </template>
    </ui-create>
</template>
<script>
import uiCreate from '@/templates/create'
import {uiCrud} from '@/components/ui'
export default {
    name: 'vwgroupCreate',
    components: {
        uiCreate,
        uiCrud
    },
    data(){
        return {
            load: false,
            alert: {
                show: false,
                type: 'indigo',
                text: ''
            },
            alertSuccess: {
                show: false,
                type: 'indigo',
                text: ''
            },
            form: {
                descricao: '',
                id_setor_impressao: null,
                comissao: '',
                ativo: null,
                ativo_des: null,
                ativo_web: null,
                ativo_mob: null
            },

            rows: [
                [
                    {
                        name: 'descricao',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Descrição',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 8,
                            lg: 8,
                            xl: 10
                        }
                    },
                    {
                        name: 'comissao',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Comissão',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 4,
                            lg: 4,
                            xl: 2
                        }
                    },
                ], [
                    {
                        name: 'id_setor_impressao',
                        value: '',
                        type: 'string',
                        field: 'autocomplete',
                        label: 'Setor de Impressão',
                        handle: 'default',
                        service: 'setores_impressao',
                        serviceParams: null,
                        optionText: 'descricao',
                        options: [],
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12
                        },
                    },
                ], [
                    {
                        name: 'ativo',
                        value: '',
                        type: 'string',
                        field: 'checkbox',
                        label: 'Grupo está ativo?',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12
                        }
                    },
                ], [
                    {
                        name: 'ativo_des',
                        value: '',
                        type: 'string',
                        field: 'checkbox',
                        label: 'Grupo será mostrado no caixa?',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12
                        }
                    },
                ], [
                    {
                        name: 'ativo_web',
                        value: '',
                        type: 'string',
                        field: 'checkbox',
                        label: 'Grupo será mostrado nos tablets?',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12
                        }
                    },
                ], [
                    {
                        name: 'ativo_mob',
                        value: '',
                        type: 'string',
                        field: 'checkbox',
                        label: 'Grupo será mostrado nos celulares?',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12
                        }
                    },
                ],
            ],

            sections: [],

            form_descricao_rules: {
                required: value => !!value || 'Descrição é obrigatória.',
                counter: value => value.length <= 32 || 'Dados inválido!',
                min: v => v.length >= 2 || 'Mínimo de 2 caracteres'
            },

            form_setor_impressao_rules: {
                required: value => !!value || 'Selecione uma impressora.'
            },

            options_form_comissao: {
                prefix: "%",
                length: 3,
                precision: 1
            },
            form_comissao_rules: {
                required: value => !!value || 'Comissão é obrigatória.',
                counter: value => value.length <= 4 || 'Dados inválido!'
            }
        }
    },

    computed:{
        id: function(){
            return this.$route.params.id || 0
        },

        title(){
            return this.id ? `Editar Grupo: ${this.id}` : 'Cadastrar Grupo'
        }
    },

    methods: {
        clearForm: function() {
            for (let prop in this.form) {
                this.form[prop] = ''
            }
        },
    
        save: function(){
            // if(this.$refs.form.validate()){
                this.overlay = true
                if(this.id !== undefined){
                    this.update()
                }else{
                    this.create()
                }
            // }
        },

        update: function(){
            this.overlay = true
            fetch(process.env.VUE_APP_API + '/group/update/' + this.id, {
                method: 'PUT',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(() => {
                    this.$root.$emit('open-snackbar-on-group', 'Empresa cadastrada com sucesso!')
                    this.clearForm()
                    this.returnRead()
                })
                .catch(error => {
                    this.$root.$emit('open-snackbar-on-group', error.message)
                })
                .finally(() => {
                    this.overlay = false
                })
        },

        create: function(){
            fetch(process.env.VUE_APP_API + '/group/create', {
                method: 'POST',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(() => {
                    this.$root.$emit('open-snackbar-on-group', 'Empresa cadastrada com sucesso!')
                    this.clearForm()
                })
                .catch(error => {
                    this.$root.$emit('open-snackbar-on-group', error.message)
                })
        },

        getData: function(){
            fetch(process.env.VUE_APP_API + '/group/read/' + this.id, {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.form = resJSON.response[0].listhelper[0]
                })
                .catch(error => {
                    this.$root.$emit('open-snackbar-on-group', error.message)
                })
        },

        getSections: function(){
            fetch(process.env.VUE_APP_API + '/generics/description/setores_impressao', {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.sections = resJSON.response
                })
                .catch(error => {
                    this.$root.$emit('open-snackbar-on-group', error.message)
                })
        }
    },

    mounted(){
        this.$root.$on('load-data-groups', (data) => {
            this.form = data
        })
    }
}
</script>